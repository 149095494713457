import React, { useState } from 'react';
import './PDFProcess.css';
import './SharedStyles.css';

function PDFProcess() {
    const [files, setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState({});
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

    const handleFileChange = (e) => {
        setFiles([...files, ...e.target.files]);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setFiles([...files, ...e.dataTransfer.files]);
    };

    const updateProgress = (fileName, status) => {
        setProgress(prevProgress => ({ ...prevProgress, [fileName]: status }));
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        e.currentTarget.classList.add('drag-over');
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.currentTarget.classList.remove('drag-over');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!files.length) {
            alert('Please select one or more PDF files to process.');
            return;
        }

        setIsLoading(true);

        for (let file of files) {
            updateProgress(file.name, 'Processing');
            const formData = new FormData();
            formData.append('pdfFiles', file);

            try {
                const response = await fetch(`${API_URL}/api/process-pdf`, {
                    method: 'POST',
                    body: formData,
                });

                if (response.ok) {
                    updateProgress(file.name, 'Completed');
                } else {
                    updateProgress(file.name, 'Failed');
                }
            } catch (error) {
                console.error('Error submitting PDF:', error);
                updateProgress(file.name, 'Error');
            }
        }

        setIsLoading(false);
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit} onDragOver={handleDragOver} onDrop={handleDrop}>
                <div className="drop-zone"
                    onClick={() => document.getElementById('fileInput').click()}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}>
                    Drag and drop files here or click to select
                    <input id="fileInput" type="file" multiple accept="application/pdf" onChange={handleFileChange} style={{ display: 'none' }} />
                </div>
                <ul className="file-list">
                    {files.map(file => (
                        <li key={file.name} className={progress[file.name]?.toLowerCase()}>
                            <span className={`file-status-icon ${progress[file.name]?.toLowerCase()}`} />
                            {file.name} - {progress[file.name] || 'Ready'}
                        </li>
                    ))}
                </ul>
                <button type="submit" disabled={isLoading}>
                    {isLoading ? 'Processing...' : 'Process PDFs'}
                </button>
            </form>
        </div>
    );
}

export default PDFProcess;
