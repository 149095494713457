import React, { useState } from 'react';
import './DOCProcess.css';

function DOCProcess() {
    const [files, setFiles] = useState([]); // Handle multiple files
    const [useHardcodedRegex, setUseHardcodedRegex] = useState(false);
    const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

    const handleFileChange = (e) => {
        setFiles([...e.target.files]); // Store all selected files
    };

    const handleCheckboxChange = () => {
        setUseHardcodedRegex(!useHardcodedRegex);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!files.length) {
            alert('Please select one or more DOCX files to process.');
            return;
        }

        const formData = new FormData();
        files.forEach(file => {
            formData.append('docFiles', file); // Append each file
        });
        formData.append('useHardcodedRegex', useHardcodedRegex);

        try {
            const response = await fetch(`${API_URL}/api/process-doc`, {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                const blob = await response.blob();
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = 'processed_docx_files.zip'; // Generic name for multiple files
                link.click();
            } else {
                alert('Failed to process DOCX files.');
            }
        } catch (error) {
            console.error('Error submitting DOCX:', error);
            alert('An error occurred while processing the DOCX files.');
        }
    };

    return (
        <div className="form-container">
            <form onSubmit={handleSubmit}>
                <input type="file" multiple accept=".docx" onChange={handleFileChange} />
                <div className="switch-container">
                    <label className="toggle-switch">
                        <input type="checkbox" checked={useHardcodedRegex} onChange={handleCheckboxChange} />
                        <span className="slider"></span>
                    </label>
                    <label className="switch-label">Use Hardcoded Regex</label>
                </div>
                <button type="submit">Process DOCX</button>
            </form>
        </div>
    );
}

export default DOCProcess;
