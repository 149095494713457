// Chat.js
import React, { useState, useRef } from 'react';
import ReactMarkdown from 'react-markdown';

const Chat = ({ selectedBot }) => {
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [threadId, setThreadId] = useState(null);
  const [useLLMAI, setUseLLMAI] = useState(false); // State to track API selection
  const chatEndRef = useRef(null);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = { role: 'user', content: input };
    setMessages(messages => [...messages, userMessage]);

    try {
      const requestBody = {
        question: input,
        botName: selectedBot,
        threadId
      };

      const apiEndpoint = useLLMAI ? '/api/llmai/chat' : '/api/openai/chat'; // Choose the endpoint based on the toggle

      const result = await fetch(`${API_URL}${apiEndpoint}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });


      if (!result.ok) {
        throw new Error('Network response was not ok');
      }

      const response = await result.json();
      if (response.error) {
        console.error('Error from server:', response.error);
      } else {
        const aiMessage = { role: 'ai', content: response.assistant };
        setMessages(messages => [...messages, aiMessage]);
        setThreadId(response.threadId);
      }
    } catch (error) {
      console.error('Fetch error:', error);
    }

    setInput('');
  };

  return (
    <>
      <h1>{selectedBot} Chat</h1>
      <div className="toggle-api">
        <label>
          <input
            type="checkbox"
            checked={useLLMAI}
            onChange={() => setUseLLMAI(!useLLMAI)}
          />
          Use Local LLM
        </label>
      </div>
      <div className="chat-window">
        {messages.map((msg, index) => (
          <div key={index} className={`message ${msg.role}`}>
            <ReactMarkdown className="markdown-content">{msg.content}</ReactMarkdown>
          </div>
        ))}
        <div ref={chatEndRef} />
      </div>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Type your message here..."
        />
        <button type="submit">Send</button>
      </form>
    </>
  );
};

export default Chat;
