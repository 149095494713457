import React, { useState } from 'react';
import './CSVAndEmbeddingOperations.css'; // Create this CSS file for styling

function CSVAndEmbeddingOperations() {
  const [file, setFile] = useState(null);
  const [delimiter, setDelimiter] = useState('');
  const [csvFileName, setCsvFileName] = useState('');
  const [csvFileCreated, setCsvFileCreated] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';
  const handleDelimiterChange = (e) => {
    setDelimiter(e.target.value);
  };
  

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setCsvFileCreated(false); // Reset CSV creation state
  };

  const createCSV = async (textContent, csvFileNameWithExtension) => {
    try {
      const createResponse = await fetch(`${API_URL}/api/createcsv`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ text: textContent, delimiterPattern: delimiter, csvFileName: csvFileNameWithExtension })
      });

      if (!createResponse.ok) {
        throw new Error(`Error during CSV creation: ${createResponse.statusText}`);
      }
      return true;
    } catch (error) {
      alert(error.message);
      return false;
    }
  };

  const calculateEmbeddings = async (csvFileNameWithExtension) => {
    try {
      const calculateResponse = await fetch(`${API_URL}/api/calculateembeddings`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ inputCsvFileName: csvFileNameWithExtension })
      });

      if (!calculateResponse.ok) {
        throw new Error(`Error during embeddings calculation: ${calculateResponse.statusText}`);
      }
      return true;
    } catch (error) {
      alert(error.message);
      return false;
    }
  };

  const handleCreateCSV = async (e) => {
    e.preventDefault();
    if (!file || !delimiter || !csvFileName) {
      alert('Please ensure the file, delimiter, and CSV file name are provided.');
      return;
    }

    const csvFileNameWithExtension = csvFileName.endsWith('.csv') ? csvFileName : `${csvFileName}.csv`;
    const fileReader = new FileReader();
    fileReader.readAsText(file, "UTF-8");
    fileReader.onload = async (e) => {
      const textContent = e.target.result;
      const csvCreated = await createCSV(textContent, csvFileNameWithExtension);
      setCsvFileCreated(csvCreated);
    };
  };

  const handleCalculateEmbeddings = async (e) => {
    e.preventDefault();
    if (!csvFileCreated) {
      alert('Please create a CSV file first.');
      return;
    }

    const csvFileNameWithExtension = csvFileName.endsWith('.csv') ? csvFileName : `${csvFileName}.csv`;
    await calculateEmbeddings(csvFileNameWithExtension);
  };

  return (
    <div className="csv-embeddings-form">
      <form onSubmit={handleCreateCSV}>
        <input type="file" onChange={handleFileChange} />
        <input type="text" placeholder="Delimiter Pattern (regex)" value={delimiter} onChange={handleDelimiterChange} />
        <input type="text" placeholder="CSV File Name" value={csvFileName} onChange={(e) => setCsvFileName(e.target.value)} />
        <button type="submit">Create CSV</button>
      <button onClick={handleCalculateEmbeddings} disabled={!csvFileCreated}>Calculate Embeddings</button>
    </form>
  </div>
  );
}

export default CSVAndEmbeddingOperations;
