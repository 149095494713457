import React, { useState, useEffect } from 'react';
import './BotEmbeddingConfiguration.css';

function BotEmbeddingConfiguration() {
  const [availableFiles, setAvailableFiles] = useState([]);
  const [selectedBot, setSelectedBot] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [topN, setTopN] = useState(10); // Default value for topN
  const [similarityMethod, setSimilarityMethod] = useState('cosine'); // Def
  const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000';

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await fetch(`${API_URL}/api/list-embedding-files`);
        if (!response.ok) throw new Error('Failed to fetch files');
        const data = await response.json();
        setAvailableFiles(data.files);
      } catch (error) {
        console.error('Error fetching files:', error);
      }
    };

    fetchFiles();
  }, []);

  useEffect(() => {
    const fetchBotConfig = async () => {
      if (selectedBot) {
        try {
          const response = await fetch(`${API_URL}/api/bot-config/${selectedBot}`);
          if (!response.ok) throw new Error('Failed to fetch bot configuration');
          const config = await response.json();
          setSelectedFiles(config.files || []);
          setTopN(config.topN || 10);
        } catch (error) {
          console.error('Error fetching bot configuration:', error);
        }
      }
    };

    fetchBotConfig();
  }, [selectedBot]);

  const handleBotChange = (e) => {
    setSelectedBot(e.target.value);
    setSelectedFiles([]);
    setSimilarityMethod('cosine');
  };

  const handleFileSelection = (e) => {
    const file = e.target.value;
    setSelectedFiles(prev => {
      if (prev.includes(file)) {
        return prev.filter(f => f !== file);
      } else {
        return [...prev, file];
      }
    });
  };

  const handleTopNChange = (e) => {
    setTopN(Number(e.target.value));
  };

  const handleSimilarityMethodChange = (e) => {
    setSimilarityMethod(e.target.value);
  };

  const updateConfiguration = async () => {
    try {
      const response = await fetch(`${API_URL}/api/update-bot-config`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ botName: selectedBot, selectedFiles, topN, similarityMethod })
      });

      if (!response.ok) throw new Error('Failed to update configuration');
      alert('Configuration updated successfully');
    } catch (error) {
      alert(`Error: ${error.message}`);
    }
  };

  return (
    <div className="bot-embedding-config">
      <div className="bot-selector">
        <select value={selectedBot} onChange={handleBotChange}>
          <option value="">Select a bot</option>
          <option value="Personal Assistant">Personal Assistant</option>
          <option value="Law Assistant">Law Assistant</option>
          <option value="Nudge Assistant">Nudge Assistant</option>
        </select>
      </div>

      <div className="topN-selector">
        <label htmlFor="topN">Top N Results: </label>
        <input
          type="number"
          id="topN"
          value={topN}
          onChange={handleTopNChange}
          min="1"
        />
      </div>

      <div className="file-checkboxes">
        {availableFiles.map(file => (
          <div className="checkbox-item" key={file}>
            <label className="toggle-switch">
              <input
                type="checkbox"
                value={file}
                checked={selectedFiles.includes(file)}
                onChange={handleFileSelection}
              />
              <span className="slider round"></span>
            </label>
            <span className="file-label">{file}</span>
          </div>
        ))}
      </div>
      
      <div className="similarity-method-selector">
        <label htmlFor="similarityMethod">Similarity Method: </label>
        <select id="similarityMethod" value={similarityMethod} onChange={handleSimilarityMethodChange}>
          <option value="cosine">Cosine Similarity</option>
          <option value="euclidean">Euclidean Distance</option>
          <option value="manhattan">Manhattan Distance</option>
          {/* Add more options here as needed */}
        </select>
      </div>
      
      <div className="update-button">
        <button onClick={updateConfiguration}>Update Configuration</button>
      </div>
    </div>
  );
}

export default BotEmbeddingConfiguration;
