import React, { useState, useEffect, useRef } from 'react';
import LandingPage from './LandingPage';
import AdminPage from './AdminPage';
import Login from './Login';
import Chat from './Chat';
import './App.css';

function App() {
  const [selectedBot, setSelectedBot] = useState('');
  const [isAdminView, setIsAdminView] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');

  // Update handleLoginSuccess to accept a role parameter
  const handleLoginSuccess = (role) => {
    setIsAuthenticated(true);
    setUserRole(role); // Set the user role
    setIsAdminView(role === 'admin'); // If the user is an admin, go to the admin view
  };

  // Function to handle logout
  const handleLogout = () => {
    setIsAuthenticated(false);
    setIsAdminView(false);
  };

  const handleBackToLanding = () => {
    setIsAdminView(false);
    setSelectedBot('');
  };

  const handleSelectBot = (bot) => {
    setSelectedBot(bot);
    setIsAdminView(false);
  };

  const renderHeaderButtons = () => {
    return (
      <>
        <button onClick={handleBackToLanding}>Home</button>

        {isAuthenticated && (
          <>
            {userRole === 'admin' && !isAdminView && (
              <button onClick={() => setIsAdminView(true)}>Admin</button>
            )}
            <button onClick={handleLogout}>Logout</button>
          </>
        )}
      </>
    );
  };



  const renderFooter = () => (
    <footer className="App-footer">
      <p>© {new Date().getFullYear()} BlazingBanana Limited</p>
      <p>All rights reserved.</p>
    </footer>
  );

  return (
    <div className="App">
      <header className="App-header">
        {renderHeaderButtons()}
      </header>

      {!isAuthenticated ? (
        <Login onLoginSuccess={handleLoginSuccess} />
      ) : isAdminView && userRole === 'admin' ? (
        <AdminPage onBackClick={handleBackToLanding} />
      ) : selectedBot ? (
        <Chat selectedBot={selectedBot} />
      ) : (
        <LandingPage onSelectBot={handleSelectBot} />
      )}

      {renderFooter()}
    </div>
  );
}

export default App;