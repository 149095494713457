import React from 'react';
import './LandingPage.css';

function LandingPage({ onSelectBot, onAdminClick }) {
  const bots = ['Personal Assistant', 'Law Assistant', 'Nudge Assistant'];

  return (
    <div className="landing-page">
      <div className="bot-selection">
        {bots.map(bot => (
          <div key={bot} className="bot-tile" onClick={() => onSelectBot(bot)}>
            <h2>{bot}</h2> {}
          </div>
        ))}
      </div>
    </div>
  );
}

export default LandingPage;
