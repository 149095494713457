import React, { useState } from 'react';
import BotEmbeddingConfiguration from './BotEmbeddingConfiguration';
import CSVAndEmbeddingOperations from './CSVAndEmbeddingOperations';
import PDFProcess from './PDFProcess';
import DOCProcess from './DOCProcess';
import './AdminPage.css';

function AdminPage({ onBackClick }) {
  const [currentView, setCurrentView] = useState('csv');

  return (
    <div className="admin-container">
      <div>
        <button onClick={() => setCurrentView('csv')}>CSV and Embedding Operations</button>
        <button onClick={() => setCurrentView('botConfig')}>Bot Embedding Configuration</button>
        <button onClick={() => setCurrentView('pdfProcessing')}>PDF Processing</button>
        <button onClick={() => setCurrentView('docProcessing')}>DOC/DOCX Processing</button>
      </div>

      {currentView === 'csv' && <CSVAndEmbeddingOperations />}
      {currentView === 'botConfig' && <BotEmbeddingConfiguration />}
      {currentView === 'pdfProcessing' && <PDFProcess />}
      {currentView === 'docProcessing' && <DOCProcess />}
    </div>
  );
}

export default AdminPage;
